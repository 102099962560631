

export class AutoResize{
    resizeObserver;
 
    constructor({selector, wrapperSelector}){
        this.selector = selector;
        this.wrapperSelector = wrapperSelector;
        // this.options = options;
        this.init();
    }

    init(){
                    
        this.resizeObserver = new ResizeObserver(entries => {
            var max = 0;
            for (let entry of entries) {
                const {height} = entry.contentRect;
                max = Math.max(max, height);
            }
            this.maxHeight = max;
            this.updateHeight();
            console.log('Size changed');
        });
        document.querySelectorAll(this.selector).forEach(el => {
            this.resizeObserver.observe(el);

        });
        document.querySelectorAll(this.wrapperSelector).forEach(el => {
            el.style.transition = 'height 0.5s ease-in-out';
        });
        // this.resizeObserver.observe();
    }
    
    updateHeight(){
        console.log({
            maxHeight: this.maxHeight,
        })
        document.querySelectorAll(this.wrapperSelector).forEach(el => {
            el.style.height = this.maxHeight + 'px';
        });
    }
    
    destroy(){
        
        document.querySelectorAll(this.selector).forEach(el => {
            this.resizeObserver.unobserve(el);
        });
        // this.resizeObserver.unobserve(divElem);
    }
        
    
}

