

class FollowCursorClass{
    trigger;
    cursor;
    currentPos = {x:0,y:0};
    isOver = false;
    constructor({cursor, trigger} = {}){
        this.cursor = document.querySelector(cursor);
        this.cursor.style.position = 'fixed';
        // this.cursor.style.left = '-9999px';
        // this.cursor.style.top = '-9999px';
        this.cursor.style.zIndex = '111';
        this.cursor.style.pointerEvents = 'none';
        this.cursor.style.display = 'none';
        this.trigger = document.querySelector(trigger);
        window.addEventListener('mousemove', this.onMouseMove.bind(this));
        this.trigger.addEventListener('mouseenter', this.onMouseEnter.bind(this));
        this.trigger.addEventListener('mouseleave', this.onMouseLeave.bind(this));

        this.update();
        
    
    }

    update(){
        if(this.isOver){
            this.onMouseMove();
        }
        requestAnimationFrame(this.update.bind(this));
    }
    // diffrence between a & b
    getDiff(a, b){
        var factor = 0.1;
        return {
            x: (a.x - b.x) * factor,
            y: (a.y - b.y) * factor
        };
    }
    onMouseMove(e){
        if(e){
            this.currentPos = {x:e.clientX-8,y:e.clientY-8};
        }
        const  a = {
            x: this.currentPos.x,
            y: this.currentPos.y
        };
        const b = {
            x: this.cursor.offsetLeft,
            y: this.cursor.offsetTop
        };
        var {x,y} = this.getDiff(a, b);
        console.log({x,y});
        this.cursor.style.left = (b.x + x) + 'px';
        this.cursor.style.top = (b.y + y) + 'px';
    }
    onMouseEnter(e){
        document.documentElement.style.cursor = 'none';
        this.cursor.style.display = 'block';
        this.isOver = true;
    }
    onMouseLeave(e){
        document.documentElement.style.cursor = '';
        // this.cursor.style.left = '-9999px';
        // this.cursor.style.top = '-9999px';
        this.cursor.style.display = 'none';
        this.isOver = false;
    }
}

export {
    FollowCursorClass
}