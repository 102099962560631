var toggle = document.querySelector('#hamburger .toggle');
if (toggle) {
    toggle.addEventListener('click', function (e) {
        toggle.classList.toggle('open');
    });
}

$(document).on('click','.offcanvas-backdrop', e=>{
    toggle.classList.remove('open');
});

$(document).on('click','.offcanvas-body a', e=>{
    toggle.classList.remove('open');
    var myOffcanvas = document.getElementById('offcanvasWithBackdrop');
    var instance = bootstrap.Offcanvas.getInstance(myOffcanvas);
    if(instance){
        instance.hide();
    }
});
// var animate = setInterval(() => {
//     toggle.checked = !toggle.checked
// }, 3000)

// document.querySelector('body').addEventListener('click', () => {
//     clearInterval(animate);
// });